import { isNotNull } from '@partstech/ui/utils';
import { AvailabilityType } from 'types/search';
import { createOption } from './createOption';
import { groupFilterOptions } from './groupFilterOptions';
import { sortAvailabilityOptions } from './sortAvailabilityOptions';
import type { AvailabilityLine, Product, Quote } from 'models';
import type { CreateFiltersConfig, FilterOption } from 'types/search';

const AvailabilityTypeNames: Record<AvailabilityType, string> = {
  [AvailabilityType.MAIN]: 'Fastest Delivery',
  [AvailabilityType.NETWORK]: 'In Network',
};

const createGroupAvailabilityOptions = (line: AvailabilityLine): FilterOption => {
  if (line.group !== null) {
    if (line.group === 'Home Store' || line.group === 'Today') {
      return createOption(line.group, 'Fastest Delivery', 1, 'radio');
    }

    if (line.group === 'Next Day' || line.group === 'Tomorrow') {
      return createOption(line.group, 'By Tomorrow', 1, 'radio');
    }

    if (line.group === '2+ Days') {
      return createOption(line.group, 'More than 2 days', 1, 'radio');
    }

    return createOption(line.group, line.group, 1, 'radio');
  }

  if (line.name === 'Local') {
    return createOption(line.id ? `${line.id}` : line.name, 'Fastest Delivery', 1, 'radio', line.name);
  }

  return createOption(line.id ? `${line.id}` : line.name, line.name, 1, 'radio');
};

const createAvailabilityByTypeOptions = (quote: Quote | null): FilterOption | null => {
  if (quote?.isInNetwork()) {
    return createOption(AvailabilityType.NETWORK, AvailabilityTypeNames[AvailabilityType.NETWORK], 1, 'radio');
  }

  if (quote?.isInStock()) {
    return createOption(AvailabilityType.MAIN, AvailabilityTypeNames[AvailabilityType.MAIN], 1, 'radio');
  }

  return null;
};

const countGroupAvailabilityOptions = (products: Product[], sortedOptions: FilterOption[], optionValues: string[]) =>
  sortedOptions.map((option, index) => ({
    ...option,
    count: products.reduce((count, product) => {
      const previousValues = optionValues.slice(0, index + 1);

      const lines = product.quote?.lines ?? [];
      const lineIds = lines.map((line) => `${line.group ?? line.id ?? line.name}`);

      const shouldCount = lineIds.some((lineId) => previousValues.some((value) => value === lineId));

      return shouldCount ? count + 1 : count;
    }, 0),
  }));

const countAvailabilityByTypeOptions = (products: Product[], sortedOptions: FilterOption[]) =>
  sortedOptions.map((option) => ({
    ...option,
    count: products.reduce((count, product) => {
      const shouldCount =
        (option.value === AvailabilityType.MAIN && product.quote?.isInStock() && !product.quote?.isInNetwork()) ||
        (option.value === AvailabilityType.NETWORK && (product.quote?.isInNetwork() || product.quote?.isInStock()));

      return shouldCount ? count + 1 : count;
    }, 0),
  }));

const getOptionsWithCount = (
  products: Product[],
  sortedOptions: FilterOption[],
  optionValues: string[],
  config: CreateFiltersConfig
) =>
  config.shouldUseStoreAvailability
    ? countGroupAvailabilityOptions(products, sortedOptions, optionValues)
    : countAvailabilityByTypeOptions(products, sortedOptions);

export const createEnhancedAvailabilityOptions = (products: Product[], config: CreateFiltersConfig) => {
  const getAvailabilityOptions = config.shouldUseStoreAvailability
    ? (product: Product) => product.quote?.lines?.map(createGroupAvailabilityOptions)
    : (product: Product) => createAvailabilityByTypeOptions(product.quote);

  const options = groupFilterOptions(products.flatMap(getAvailabilityOptions).filter(isNotNull));
  const sortedOptions = sortAvailabilityOptions(options, config.supplierId);

  const optionValues = sortedOptions.map((option) => option.value);

  const optionsWithCount = getOptionsWithCount(products, sortedOptions, optionValues, config);

  return groupFilterOptions(optionsWithCount);
};
