import type { CheckedFilters, DynamicAttributeName, Prefill, SearchTypes } from 'types/search';

export enum SearchTabs {
  POPULAR = 'Popular Parts',
  TIRES = 'Tires',
  FLUIDS_CHEMICALS = 'Fluids & Chemicals',
  CATEGORIES = 'Categories',
  RECENT_SEARCHES = 'Recent Searches',
  DIAGRAMS = 'Vehicle Diagrams',
  JOBS = 'Jobs',
  SERVICE_GUIDES = 'Service Guides',
}

export enum SearchInputSize {
  DEFAULT = 'default',
  DENSE = 'dense',
}

export type TireSizeFieldName = 'width' | 'height' | 'rim';

export type TireSizeFormData = Record<TireSizeFieldName, string | null>;

type VehicleQuery = {
  vehicle?: string;
  vin?: string;
  plate?: string;
  state?: string;
};

export type PartTypeQuery = {
  part_text: string;
  part_text_id: string;
  attributes?: Record<DynamicAttributeName, string[]>;
  fit?: string[];
  job_id?: string;
  prefill?: Prefill;
};

type KeywordQuery = {
  part_text: string;
};

type PartsPartNumberQuery = {
  type: SearchTypes.PARTS;
  partnumber: string;
  manufacturers?: string[];
};

type TiresPartNumberQuery = {
  type: SearchTypes.TIRES;
  partnumber: string;
};

type TireSizeQuery = {
  /** "220-55-18" or "220-55-18,225-60-19" */
  tire_sizes: string;
};

export type PartNumberQuery = PartsPartNumberQuery | TiresPartNumberQuery;

export type SupplierQuery = {
  selected_distributor?: string;
  dealers?: string;
};

export type SearchSequenceQuery = {
  step?: string;
  task_type?: string;
  job_id?: string;
};

export type SearchQuery = VehicleQuery &
  (PartTypeQuery | KeywordQuery | PartNumberQuery | TireSizeQuery) &
  Partial<CheckedFilters> &
  Partial<SupplierQuery> &
  Partial<SearchSequenceQuery>;
