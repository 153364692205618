import { api as generatedApi } from './GetSubscriptionUpcomingInvoice.generated';
import type { UpcomingInvoicePlan } from 'shared/api';

const api = generatedApi.enhanceEndpoints({
  endpoints: {
    GetSubscriptionUpcomingInvoice: {
      forceRefetch: ({ currentArg, previousArg }) => currentArg !== previousArg,
    },
  },
});

const { useGetSubscriptionUpcomingInvoiceQuery } = api;

type Args = {
  couponId?: string;
  plans: UpcomingInvoicePlan[];
  skip?: boolean;
};

export const useGetSubscriptionUpcomingInvoice = ({ couponId, plans, skip }: Args) => {
  const { currentData, ...rest } = useGetSubscriptionUpcomingInvoiceQuery({ input: { couponId, plans } }, { skip });

  const upcomingInvoice = currentData?.currentUser?.activeMember?.shop?.subscriptionUpcomingInvoice;

  return {
    ...rest,
    upcomingInvoice,
  };
};
