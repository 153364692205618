/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 */

import { api } from 'shared/api';
import { UpcomingInvoiceFragmentDoc } from './UpcomingInvoice.generated';
import type * as Types from '../../../../../shared/api/graphql/graphql';

export type GetSubscriptionUpcomingInvoiceQueryVariables = Types.Exact<{
  input: Types.UpcomingInvoiceInput;
}>;

export type GetSubscriptionUpcomingInvoiceQuery = {
  __typename?: 'Query';
  currentUser?: {
    __typename?: 'User';
    activeMember?: {
      __typename?: 'ShopMember';
      shop?: {
        __typename?: 'Shop';
        subscriptionUpcomingInvoice?: {
          __typename?: 'SubscriptionUpcomingInvoice';
          totalDiscount?: Types.Scalars['BigDecimal']['input'] | null;
          grandTotal: Types.Scalars['BigDecimal']['input'];
          nextPaymentAmount: Types.Scalars['BigDecimal']['input'];
          tax: Types.Scalars['BigDecimal']['input'];
          total: Types.Scalars['BigDecimal']['input'];
        } | null;
      } | null;
    } | null;
  } | null;
};

export const GetSubscriptionUpcomingInvoiceDocument = `
    query GetSubscriptionUpcomingInvoice($input: UpcomingInvoiceInput!) {
  currentUser {
    activeMember {
      shop {
        subscriptionUpcomingInvoice(input: $input) {
          totalDiscount
          ...UpcomingInvoice
        }
      }
    }
  }
}
    ${UpcomingInvoiceFragmentDoc}`;

const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    GetSubscriptionUpcomingInvoice: build.query<
      GetSubscriptionUpcomingInvoiceQuery,
      GetSubscriptionUpcomingInvoiceQueryVariables
    >({
      query: (variables) => ({ document: GetSubscriptionUpcomingInvoiceDocument, variables }),
    }),
  }),
});

export { injectedRtkApi as api };
export const { useGetSubscriptionUpcomingInvoiceQuery, useLazyGetSubscriptionUpcomingInvoiceQuery } = injectedRtkApi;
