import { Icon } from '@partstech/ui';
import { NavigationItem } from '../NavigationItem';
import { Memberships } from './Memberships';
import { useMembershipsButton } from './useMembershipsButton';

type Props = {
  onClick?: () => void;
};

export const MembershipsButton = ({ onClick }: Props) => {
  const { shopName, shopMemberships, supplierMemberships, showButton, isOpenList, openList, closeList } =
    useMembershipsButton({ onClick });

  if (!showButton) {
    return null;
  }

  return (
    <>
      <NavigationItem
        title={shopName}
        onClick={openList}
        adornmentBefore={<Icon name="location_on" color="subtleText" />}
        isActive={isOpenList}
        data-testid="membershipsMenuButton"
      />

      {isOpenList && (
        <Memberships shopMemberships={shopMemberships} supplierMemberships={supplierMemberships} onClose={closeList} />
      )}
    </>
  );
};
