import { Availability, AvailabilityType } from 'types/search';
import type { AvailabilityLine, Product } from 'models';
import type { CheckedFilters } from 'types/search';

const isMatchingAvailabilityLine = (line: AvailabilityLine, values: CheckedFilters['availability']): boolean =>
  line.group
    ? values?.some((val) => line.isMatchingGroup({ group: val }))
    : values?.some((val) => line.isEqual({ id: Number(val), name: val }));

export const filterByEnhancedAvailability = (
  product: Product,
  values: CheckedFilters['availability'],
  products: Product[]
): boolean => {
  const hasMatchingLinesInProducts = products.some((item) =>
    item.quote?.lines?.some((line) => isMatchingAvailabilityLine(line, values))
  );

  return (
    values.some((value) => {
      if (value === Availability.OUT_OF_STOCK) {
        return product.quote?.isOutOfStock();
      }

      if (value === Availability.BACK_ORDER) {
        return product.quote?.isBackOrder();
      }

      if (value === Availability.IN_STOCK) {
        return product.quote?.isInStock();
      }

      if (value === AvailabilityType.MAIN) {
        return product.quote?.isInStock() && !product.quote?.isInNetwork();
      }

      if (value === AvailabilityType.NETWORK) {
        return product.quote?.isInNetwork() || product.quote?.isInStock();
      }

      return !hasMatchingLinesInProducts
        ? product.quote?.isInStock()
        : product.quote?.lines?.some((line) => isMatchingAvailabilityLine(line, values));
    }) ?? false
  );
};
