import { isNotNull } from '@partstech/ui/utils';
import { useMemo } from 'react';
import { createPartTypeFromQuery } from 'factories';
import { LegacyJob, PartNumber } from 'models';
import { useGetTypeaheadQuery } from 'store/queries/typeahead/GetTypeahead.generated';
import { SuggestedPartTypeOption } from '../../models';
import type { GetTypeaheadQuery } from 'store/queries/typeahead/GetTypeahead.generated';
import type { ArrayItemType } from 'types/general';

type Props = {
  search: string;
  skip?: boolean;
};

type TypeaheadSuggestionsQuery = NonNullable<GetTypeaheadQuery['typeahead']>;

const createTypeaheadOption = (option: ArrayItemType<TypeaheadSuggestionsQuery>) => {
  if ('partNumber' in option.item) {
    return new PartNumber(option.item);
  }

  if ('partTypes' in option.item) {
    const { id, name, partTypes, aliases } = option.item;

    return new LegacyJob({
      id,
      name,
      aliases: aliases ?? [],
      partTypes: partTypes?.map((partType) => createPartTypeFromQuery(partType)) ?? [],
    });
  }

  if ('application' in option.item) {
    return option.suggested ? new SuggestedPartTypeOption(option.item) : createPartTypeFromQuery(option.item, '');
  }

  return null;
};

const createTypeaheadOptions = (typeahead: GetTypeaheadQuery['typeahead']) => {
  const loadedOptions = typeahead?.map((item) => createTypeaheadOption(item)).filter(isNotNull) ?? [];

  const partNumberOption = loadedOptions.find((option): option is PartNumber => option instanceof PartNumber);

  const searchedOptions = loadedOptions.filter((option) => !(option instanceof SuggestedPartTypeOption));
  const suggestedOptions = loadedOptions.filter((option) => option instanceof SuggestedPartTypeOption);

  if (searchedOptions.length === 0) {
    return suggestedOptions;
  }

  return partNumberOption
    ? [
        new PartNumber({ id: partNumberOption.partNumber, partNumber: partNumberOption.partNumber, brandName: null }),
        ...searchedOptions,
      ]
    : searchedOptions;
};

export const useGetTypeaheadSuggestions = ({ search, skip }: Props) => {
  const { currentData, ...props } = useGetTypeaheadQuery({ search }, { skip: skip || search.length === 0 });

  const options = useMemo(() => createTypeaheadOptions(currentData?.typeahead), [currentData?.typeahead]);
  const isPartNumber = currentData?.isPartNumber ?? false;

  return { options, isPartNumber, ...props };
};
