import { css } from '@emotion/react';
import { Box, Button, Checkbox, Link, Typography, useMedia } from '@partstech/ui';
import { useCreateModal, useOpen } from '@partstech/ui/hooks';
import { useCallback, useMemo } from 'react';
import { EditHoursModal } from '../EditHoursModal';
import type { BoxProps } from '@partstech/ui';
import type { LaborApplication as LaborApplicationModel } from 'models';
import type { MouseEvent } from 'react';

const styles = {
  title: css`
    word-break: break-word;
  `,
};

type Props = BoxProps & {
  laborApplication: LaborApplicationModel;
  checked: boolean;
  customDuration?: number;
  onToggle: (laborApplication: LaborApplicationModel) => void;
  onChangeCustomDuration: (laborApplicationId: string, customDuration: number) => void;
  skippedNotes?: string[];
};

export const LaborApplication = ({
  laborApplication,
  checked,
  customDuration,
  onToggle,
  onChangeCustomDuration,
  skippedNotes,
  ...boxProps
}: Props) => {
  const { isMobile } = useMedia();
  const { isOpen: expanded, toggle: toggleExpanded } = useOpen(true);
  const { open, close } = useCreateModal(
    EditHoursModal,
    { canBeBottomSheet: true, size: 'dialog' },
    { customId: 'editHours' }
  );

  const filteredNotes = useMemo(
    () =>
      skippedNotes ? laborApplication.notes.filter((note) => !skippedNotes.includes(note)) : laborApplication.notes,
    [laborApplication.notes, skippedNotes]
  );

  const toggleCollapse = useCallback(
    (e: MouseEvent) => {
      e.preventDefault();
      toggleExpanded();
    },
    [toggleExpanded]
  );

  const handleSaveHours = useCallback(
    (value: number) => {
      onChangeCustomDuration(laborApplication.id, value);
      close();
    },
    [close, laborApplication.id, onChangeCustomDuration]
  );

  const handleChange = useCallback(() => {
    onToggle(laborApplication);
  }, [laborApplication, onToggle]);

  const handleClick = useCallback(() => {
    open({
      defaultValue: customDuration || laborApplication.duration,
      estimatedValue: laborApplication.duration,
      onSave: handleSaveHours,
    });
  }, [customDuration, handleSaveHours, laborApplication.duration, open]);

  return (
    <Box display="flex" alignItems="center" data-testid="laborItem" {...boxProps}>
      <Checkbox onChange={handleChange} checked={checked} />

      <Box width="100%">
        <Typography variant="subtitle2" css={styles.title}>
          {laborApplication.name}
        </Typography>

        {isMobile && laborApplication.skill && (
          <Typography component="p" variant="caption" color="subtleText">
            Skill: {laborApplication.skill}
          </Typography>
        )}

        {expanded && filteredNotes.length > 0 && <Typography variant="bodyText2">{filteredNotes.join(' ')}</Typography>}

        {filteredNotes.length > 0 && (
          <Link onClick={toggleCollapse} variant="overline" color="linkText" underline="hover">
            {expanded ? 'HIDE DETAILS' : 'VIEW DETAILS'}
          </Link>
        )}
      </Box>

      <Box
        height={8}
        width={{ sm: 29, md: 45 }}
        display="flex"
        justifyContent="flex-end"
        alignItems="center"
        flexShrink="0"
      >
        {!isMobile && laborApplication.skill && (
          <Box mr={6}>
            <Typography variant="bodyText2" color="subtleText">
              Skill: {laborApplication.skill}
            </Typography>
          </Box>
        )}

        <Typography variant={isMobile ? 'bodyText2' : 'bodyText1'}>
          {(customDuration || laborApplication.duration).toFixed(2)} hours
        </Typography>

        <Box ml={1}>
          <Button leadingIcon="edit" size="dense" variant="light" onClick={handleClick} data-testid="editHoursButton" />
        </Box>
      </Box>
    </Box>
  );
};
