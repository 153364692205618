import { css } from '@emotion/react';
import { Box, Button, Card, Divider, ImageFallback, TextCut, Typography, useMedia } from '@partstech/ui';
import { useOpen } from '@partstech/ui/hooks';
import { Fragment, useCallback, useMemo } from 'react';
import { LaborApplication } from '../LaborApplication';
import type { CartOrderItem, LaborApplication as LaborApplicationModel } from 'models';

const styles = {
  image: css`
    flex-shrink: 0;
    object-fit: contain;
  `,
  title: css`
    overflow: hidden;
  `,
  laborItems: (expanded: boolean) => css`
    display: ${expanded ? 'block' : 'none'};
  `,
};

type Props = {
  orderItem: CartOrderItem;
  laborApplications: LaborApplicationModel[];
  selectedLaborApplicationIds: string[];
  customDurations: Record<string, number>;
  onToggleLaborApplication: (laborApplication: LaborApplicationModel) => void;
  onChangeCustomDuration: (laborApplicationId: string, customDuration: number) => void;
  onCollapse: () => void;
};

/** @deprecated the same component as in the service guides should be used (PD-4681) */
export const LaborCard = ({
  orderItem,
  laborApplications,
  selectedLaborApplicationIds,
  customDurations,
  onToggleLaborApplication,
  onChangeCustomDuration,
  onCollapse,
}: Props) => {
  const { isMobile } = useMedia();
  const { isOpen: expanded, toggle: toggleCollapse } = useOpen(true);

  const filteredApplications = useMemo(
    () => laborApplications.filter((application) => application.orderItemId === orderItem.id),
    [laborApplications, orderItem.id]
  );

  const hasApplications = filteredApplications.length > 0;

  const groupedApplications = useMemo(() => {
    const parentApplications = filteredApplications.filter((application) => !application.parentId);

    return parentApplications.map((parentApplication) => ({
      parentApplication,
      children: filteredApplications.filter((application) => application.parentId === parentApplication.applicationId),
    }));
  }, [filteredApplications]);

  const handleCollapse = useCallback(() => {
    toggleCollapse();
    onCollapse();
  }, [onCollapse, toggleCollapse]);

  return (
    <Card p={2} background={hasApplications ? 'white' : 'mono2'}>
      <Box display="flex" alignItems="center">
        <ImageFallback src={orderItem.image} height={36} width={36} css={styles.image} />

        <Box
          display="flex"
          width="100%"
          alignItems="center"
          justifyContent="space-between"
          flexWrap={isMobile && !hasApplications ? 'wrap' : 'nowrap'}
        >
          <Box mx={2} css={styles.title}>
            <Typography component="div">
              <TextCut linesCount={1}>{orderItem.title}</TextCut>
            </Typography>
          </Box>

          {hasApplications ? (
            <Button leadingIcon={expanded ? 'expand_less' : 'expand_more'} variant="light" onClick={handleCollapse} />
          ) : (
            <Box mr={3} ml={isMobile ? 2 : 3} flexShrink="0">
              <Typography variant="caption">No labor found for this part.</Typography>
            </Box>
          )}
        </Box>
      </Box>

      {hasApplications && (
        <div css={styles.laborItems(expanded)}>
          {groupedApplications.map((group, index) => (
            <Fragment key={group.parentApplication.id}>
              {index > 0 && <Divider />}

              <LaborApplication
                my={3}
                mx={2}
                laborApplication={group.parentApplication}
                checked={selectedLaborApplicationIds.includes(group.parentApplication.id)}
                customDuration={customDurations[group.parentApplication.id]}
                onToggle={onToggleLaborApplication}
                onChangeCustomDuration={onChangeCustomDuration}
              />

              {group.children.map((child) => (
                <Box key={child.id} ml={6}>
                  <LaborApplication
                    my={3}
                    mx={2}
                    laborApplication={child}
                    checked={selectedLaborApplicationIds.includes(child.id)}
                    customDuration={customDurations[child.id]}
                    onToggle={onToggleLaborApplication}
                    onChangeCustomDuration={onChangeCustomDuration}
                  />
                </Box>
              ))}
            </Fragment>
          ))}
        </div>
      )}
    </Card>
  );
};
