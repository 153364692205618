import { isNotNull } from '@partstech/ui/utils';
import { useMemo } from 'react';
import { UniversalPartType } from 'models';
import { isMatchingString } from 'shared/lib/string';
import { useDefaultOptions } from '../useDefaultOptions';
import type { PartType } from 'models';

const createPartTypeSuggestionsWithFoundAttribute = (partType: PartType, search: string) => {
  if (!(partType instanceof UniversalPartType) || !partType.attributes) {
    return [];
  }

  return (
    partType.attributes
      ?.flatMap((attribute) =>
        attribute.values
          ?.filter((value) => isMatchingString(value, search))
          .map((value) => {
            const selectedPartType = new UniversalPartType(partType);
            selectedPartType.setSelectedAttributes({ [attribute.name]: [value] });
            return selectedPartType;
          })
      )
      .filter(isNotNull) ?? []
  );
};

type Props = {
  search: string;
  skip: boolean;
};

export const useAttributeSearch = ({ search, skip = false }: Props): { partTypes: PartType[] } => {
  const { partTypes } = useDefaultOptions();

  const foundPartTypes = useMemo(() => {
    if (!search || skip) {
      return [];
    }

    return partTypes.flatMap((partType) => {
      const suggestions = createPartTypeSuggestionsWithFoundAttribute(partType, search);

      if (suggestions.length > 0) {
        return [partType, ...suggestions];
      }

      return [];
    });
  }, [partTypes, search, skip]);

  return { partTypes: foundPartTypes };
};
