import { useCallback } from 'react';
import { useStepProgress } from 'features/signUp';
import { useCreateManagementSystem } from 'hooks/shopManagementSystems';
import { OnboardStepType } from 'shared/api';
import type { CreateShopManagementSystemMutation } from 'features/shop/api/createShopManagementSystem/CreateShopManagementSystem.generated';

type Args = {
  onSuccess: () => void;
};

export const useSelectManagementSystem = ({ onSuccess }: Args) => {
  const { selectOption } = useStepProgress({ step: OnboardStepType.Sms, onSuccess });

  const handleSucessCreateManagementSystem = useCallback(
    (data: CreateShopManagementSystemMutation) => {
      const managementSystemName = data?.createShopManagementSystemRelation?.shop?.managementSystem?.name;

      if (managementSystemName) {
        selectOption(managementSystemName);
      }
    },
    [selectOption]
  );

  const [createManagementSystem] = useCreateManagementSystem({
    onSuccess: handleSucessCreateManagementSystem,
  });

  const selectSystem = useCallback(
    (managementSystemId: string) => () => {
      createManagementSystem({ managementSystemId });
    },
    [createManagementSystem]
  );

  const selectNoSms = useCallback(() => {
    createManagementSystem({ noSms: true });
  }, [createManagementSystem]);

  return {
    selectNoSms,
    selectSystem,
  };
};
