import { sortBy } from '@partstech/ui/utils';
import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import { DEFAULT_QUOTE_QUANTITY, defaultQuotePricePackage } from 'constant';
import { api as createTireQuoteApi } from 'features/comparedQuote/api/saveComparedQuote/createComparedQuote/CreateTireQuote.generated';
import { arraySwap } from 'shared/lib/array';
import { selectIdFromQuery, urlParamsToQuery, getSlotId } from 'utils';
import { api as calculatePriceApi } from '../mutations/currentUser/retailPricing/pricePackages/calculatePackagePrice/CalculatePackagePrice.generated';
import { logout } from './user/account';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { Product } from 'models';

import type { ActionWithPayload, RootState } from 'store';
import type { FetPriceLine, PriceLine, PricePackage } from 'types/pricePackage';
import type { ProductQueryParams } from 'types/product';
import type { QuoteCalculation, NotificationEmails, QuoteSlot, RawSlot } from 'types/quote';

export type QuotePriceLine = PriceLine & { enabled: boolean; isPercentage?: boolean; isCustom?: boolean };

export type QuotePricePackage = {
  id?: number | null;
  name?: string;
  items: QuotePriceLine[];
  isDefault: boolean;
};

export type QuoteFilledSlot = Omit<QuoteSlot, 'product' | 'urlParams'> & {
  product: Exclude<QuoteSlot['product'], null>;
  urlParams: Exclude<RawSlot['urlParams'], null>;
};

export type Quote = {
  id: string | null;
  quantity: number;
  slots: RawSlot[];
  taxRate: number | null;
  selectedPricePackage: QuotePricePackage['id'];
  packages: QuotePricePackage[];
  repairOrderId: string | null;
  customerEmail: string;
  customerEmailNote: string;
  isLoading: boolean;
  isSuccess: boolean;
  error: string | null;
  calculationError?: string | null;
  isShareModalOpen: boolean;
  notificationEmails: string[];
};

export type ReadonlyQuote = Omit<
  Quote,
  | 'selectedPricePackage'
  | 'taxRate'
  | 'slots'
  | 'id'
  | 'repairOrderId'
  | 'customerEmail'
  | 'customerEmailNote'
  | 'notificationEmails'
> & {
  id: string;
  slots: (Omit<QuoteFilledSlot, 'calculation'> & { calculation: QuoteCalculation })[];
};

const emptySlot: RawSlot = {
  urlParams: null,
  calculation: null,
  note: null,
};

const initialState: Quote = {
  id: null,
  quantity: DEFAULT_QUOTE_QUANTITY,
  slots: [emptySlot, emptySlot, emptySlot],
  taxRate: 0,
  repairOrderId: null,
  customerEmail: '',
  customerEmailNote: '',
  selectedPricePackage: null,
  packages: [],
  isLoading: false,
  isSuccess: false,
  error: null,
  calculationError: null,
  isShareModalOpen: false,
  notificationEmails: [],
};

export const selectQuoteState = (state: RootState) => state.features.quote;

export const selectRawSlots = createSelector([selectQuoteState], (state) => state.slots);

export const selectIsAllQuoteSlotsEmpty = createSelector([selectQuoteState], (state) =>
  state.slots.every((slot) => slot.urlParams === null)
);

export const selectQuoteQuantity = createSelector([selectQuoteState], (state) => state.quantity);

export const selectQuotePricePackage = createSelector(
  [selectQuoteState],
  (state) =>
    state.packages.find((pricePackage) => pricePackage.id === state.selectedPricePackage) ||
    state.packages[0] ||
    defaultQuotePricePackage
);

export const selectQuotePackages = createSelector([selectQuoteState], (state) =>
  sortBy(state.packages, 'isDefault').reverse()
);

export const selectQuoteTaxRate = createSelector([selectQuoteState], (state) => state.taxRate ?? 0);

export const selectQuoteRepairOrderId = createSelector([selectQuoteState], (state) => state.repairOrderId ?? '');

export const selectQuoteCustomerEmail = createSelector([selectQuoteState], (state) => state.customerEmail ?? '');

export const selectQuoteCustomerEmailNote = createSelector(
  [selectQuoteState],
  (state) => state.customerEmailNote ?? ''
);

export const selectQuoteId = createSelector([selectQuoteState], (state) => state.id);

export const selectIsQuoteCreated = createSelector([selectQuoteState], (state) => Boolean(state.id));

export const selectNotificationEmails = createSelector([selectQuoteState], (state) => state.notificationEmails ?? []);

export const selectCalculationError = createSelector([selectQuoteState], (state) => state.calculationError);

export const selectPriceLines = createSelector([selectQuotePricePackage], (selectedPricePackage) =>
  selectedPricePackage?.items.reduce<PricePackage['items']>((result, { enabled, ...item }) => {
    if (enabled) {
      return [...result, item];
    }

    return result;
  }, [])
);

export const restoreQuote = createAsyncThunk<Quote, Quote | undefined>(
  'features/quote/restoreQuote',
  (quote?: Quote) => {
    if (!quote) {
      throw new Error('Empty quote');
    }

    if (quote.slots.some((slot) => slot === null)) {
      throw new Error('Empty slot or slots');
    }

    return quote;
  }
);

type UpdateQuotePricePackagesPayload = {
  ignoreCustomLines: boolean;
  pricePackages: Array<PricePackage | QuotePricePackage>;
};

const quoteSlice = createSlice({
  name: 'quote',
  initialState,
  reducers: {
    setQuotePricing: (
      state,
      action: ActionWithPayload<string, Pick<Quote, 'taxRate' | 'selectedPricePackage' | 'packages'>>
    ) => {
      state.taxRate = action.payload.taxRate;
      state.selectedPricePackage = action.payload.selectedPricePackage;
      state.packages = action.payload.packages;
    },
    setQuoteProductFet: (
      state,
      action: ActionWithPayload<string, { slotIndex: number; fetPriceLine: FetPriceLine }>
    ) => {
      const { slotIndex, fetPriceLine } = action.payload;

      const slot = state.slots[slotIndex];
      if (!slot) {
        return;
      }

      slot.fetPriceLine = fetPriceLine;
    },
    setQuoteQuantity: (state, action: ActionWithPayload<string, number>) => {
      state.quantity = action.payload;
      state.slots = state.slots.map((slot) => ({ ...slot, calculation: null }));
    },
    setQuoteItemPrice: (state, action: PayloadAction<{ value: number; productQueryParams: ProductQueryParams }>) => {
      const { value, productQueryParams } = action.payload;
      const productIndex = state.slots.findIndex(
        (slot) => slot.urlParams && selectIdFromQuery(slot.urlParams) === selectIdFromQuery(productQueryParams)
      );
      const nextSlots = [...state.slots];

      const nextSlot = nextSlots[productIndex];
      if (nextSlot) {
        nextSlot.customPrice = value;
        nextSlot.calculation = null;
      }

      state.slots = nextSlots;
    },
    addProductToQuote: (state, action: ActionWithPayload<string, Product>) => {
      const product = action.payload;

      const urlParams = urlParamsToQuery({
        partNumberId: product.getPartNumberId(),
        partTypeId: product.partTypeId ? Number(product.partTypeId) : null,
        credentialId: product.credentialId ? Number(product.credentialId) : null,
        supplierId: product.supplier?.id ? Number(product.supplier?.id) : null,
        lineCardId: product.lineCardId,
        vehicleId: product.vehicleId,
      });

      const emptyIndex = state.slots.findIndex((slot) => slot.urlParams === null);

      if (emptyIndex > -1) {
        state.slots[emptyIndex] = {
          urlParams,
          calculation: null,
          note: '',
          customPrice: null,
          ...(product.fetCharge && {
            fetPriceLine: {
              isApplyTax: false,
              isPerTire: true,
              name: 'FET',
              type: 'FET',
              price: product.fetCharge,
              description: null,
            },
          }),
        };
      }
    },
    setQuotePricePackage: (state, action: ActionWithPayload<string, QuotePricePackage['id']>) => {
      state.selectedPricePackage = action.payload;
    },
    updateQuotePackages: (state, action: ActionWithPayload<string, UpdateQuotePricePackagesPayload>) => {
      const isCustom = (priceLine: QuotePriceLine) => priceLine.isCustom;
      const packages = action.payload.pricePackages;

      if (!state.selectedPricePackage) {
        state.selectedPricePackage = packages[0]?.id ?? 0;
      }

      const isQuotePriceLine = (priceLine: PriceLine | QuotePriceLine): priceLine is QuotePriceLine =>
        'enabled' in priceLine;

      const getUpdatedPriceLine = (priceLine: QuotePriceLine | PriceLine) => ({
        ...priceLine,
        enabled: isQuotePriceLine(priceLine) ? priceLine.enabled : true,
      });

      const getUpdatedPricePackage = (newPricePackage: PricePackage | QuotePricePackage) => {
        const quotePackage = state.packages.find((pricePackage) => pricePackage.id === newPricePackage.id);
        const customQuotePriceLines = action.payload.ignoreCustomLines
          ? []
          : (quotePackage?.items.filter(isCustom) ?? []);

        return {
          ...newPricePackage,
          items: [...newPricePackage.items.map(getUpdatedPriceLine), ...customQuotePriceLines],
        };
      };

      state.packages = packages.length > 0 ? (state.packages = packages.map(getUpdatedPricePackage)) : [];
    },
    updateQuoteTaxRate: (state, action: ActionWithPayload<string, number | null>) => {
      state.taxRate = action.payload;
    },
    removeIndex: (state, action: ActionWithPayload<string, number>) => {
      const isLastSlot = state.slots.filter((slot) => slot.urlParams).length === 1;

      if (isLastSlot) {
        return initialState;
      }

      state.slots[action.payload] = emptySlot;

      return state;
    },
    removeProductFromQuote: (state, action: ActionWithPayload<string, string>) => {
      const isLastSlot = state.slots.filter((slot) => slot.urlParams).length === 1;

      if (isLastSlot) {
        return initialState;
      }

      const productIndex = state.slots.findIndex(
        (slot) => slot.urlParams && selectIdFromQuery(slot.urlParams) === action.payload
      );

      if (productIndex > -1) {
        state.slots[productIndex] = emptySlot;
      }

      return state;
    },
    reorderQuoteSlots: (state, action: ActionWithPayload<string, { oldIndex: number; newIndex: number }>) => {
      const { oldIndex, newIndex } = action.payload;

      state.slots = arraySwap(state.slots, oldIndex, newIndex);
    },
    setQuoteSlotCalculation: (
      state,
      action: ActionWithPayload<string, { slotId: string; calculation: QuoteCalculation }>
    ) => {
      const { slotId, calculation } = action.payload;

      state.slots = state.slots.map((item) => (getSlotId(item) === slotId ? { ...item, calculation } : item));
    },
    resetQuoteCalculations: (state) => {
      state.calculationError = null;
      state.slots = state.slots.map((slot) => slot && { ...slot, calculation: null });
    },
    resetQuoteCalculationByIndex: (state, action: PayloadAction<number>) => {
      const slot = state.slots[action.payload];
      if (!slot) {
        return;
      }

      slot.calculation = null;
    },
    editNotificationEmails: (state, action: ActionWithPayload<string, NotificationEmails>) => {
      const { notificationEmails } = action.payload;
      state.notificationEmails = notificationEmails;
    },
    editQuoteNoteById: (state, action: PayloadAction<{ value: string; productQueryParams: ProductQueryParams }>) => {
      const { value, productQueryParams } = action.payload;
      const productIndex = state.slots.findIndex(
        (slot) => slot.urlParams && selectIdFromQuery(slot.urlParams) === selectIdFromQuery(productQueryParams)
      );
      const nextSlots = [...state.slots];
      const nextSlot = nextSlots[productIndex];
      if (nextSlot) {
        nextSlot.note = value;
      }

      state.slots = nextSlots;
    },
    setIsShareModalOpen: (state, action: PayloadAction<boolean>) => {
      state.isShareModalOpen = action.payload;
    },
    resetQuote: () => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(restoreQuote.fulfilled, (_: unknown, action) => action.payload)
      .addCase(logout.fulfilled, () => initialState)
      .addMatcher(createTireQuoteApi.endpoints.CreateTireQuote.matchFulfilled, (state, action) => {
        const { customerInfo } = action.meta.arg.originalArgs.input;
        state.repairOrderId = customerInfo.repairOrderId;

        if (customerInfo.email) {
          state.customerEmail = customerInfo.email;
        }
        if (customerInfo.note) {
          state.customerEmailNote = customerInfo.note;
        }

        state.id = action.payload.createTireQuote?.hash ?? null;
      })
      .addMatcher(calculatePriceApi.endpoints.CalculatePackagePrice.matchPending, (state) => {
        state.calculationError = null;
      })
      .addMatcher(calculatePriceApi.endpoints.CalculatePackagePrice.matchFulfilled, (state) => {
        state.calculationError = null;
      })
      .addMatcher(calculatePriceApi.endpoints.CalculatePackagePrice.matchRejected, (state, action) => {
        state.calculationError = action.error.message ?? 'Something went wrong';
      });
  },
});

export const quote = quoteSlice.reducer;

export const {
  addProductToQuote,
  setQuotePricing,
  setQuoteQuantity,
  updateQuotePackages,
  setQuotePricePackage,
  updateQuoteTaxRate,
  removeIndex,
  removeProductFromQuote,
  reorderQuoteSlots,
  resetQuoteCalculations,
  resetQuoteCalculationByIndex,
  setQuoteProductFet,
  editQuoteNoteById,
  setQuoteItemPrice,
  editNotificationEmails,
  setIsShareModalOpen,
  resetQuote,
  setQuoteSlotCalculation,
} = quoteSlice.actions;
