/**
 * THIS FILE IS AUTOGENERATED, DO NOT EDIT IT!
 */

import { CartLaborItemFragmentDoc } from '../../../../shared/api/graphql/fragment/CartLaborItem.generated';
import type * as Types from '../../../../shared/api/graphql/graphql';

export type CartOrderItemFragment = {
  __typename?: 'CartOrderItem';
  id: string;
  image?: Types.Scalars['String']['input'] | null;
  partName: Types.Scalars['String']['input'];
  partNumber: Types.Scalars['String']['input'];
  partNumberId: Types.Scalars['String']['input'];
  lineCardId?: number | null;
  origin: Types.CartOrderItemOrigin;
  quantity: number;
  tireSize?: Types.Scalars['String']['input'] | null;
  vin?: Types.Scalars['String']['input'] | null;
  builtItem?: {
    __typename?: 'BuiltItem';
    sponsorType: Types.OrderItemSponsorType;
    bundled: Types.Scalars['Boolean']['input'];
    discount: Types.Scalars['Float']['input'];
    errors: Array<{ __typename?: 'OrderItemError'; message: Types.Scalars['String']['input'] }>;
    availableMotorLaborApplications?: Array<{
      __typename?: 'CartLabor';
      duration: Types.Scalars['BigDecimal']['input'];
      id: string;
      name?: Types.Scalars['String']['input'] | null;
      notes?: Array<Types.Scalars['String']['input']> | null;
      parentId?: string | null;
      skillCode: Types.Scalars['String']['input'];
      taxonomyName: Types.Scalars['String']['input'];
      baseLaborTimeDescription?: Types.Scalars['String']['input'] | null;
      descriptions?: Array<Types.Scalars['String']['input']> | null;
      position?: { __typename?: 'Position'; name: Types.Scalars['String']['input'] } | null;
      attributes?: Array<{
        __typename?: 'Attribute';
        name?: Types.Scalars['String']['input'] | null;
        text?: Types.Scalars['String']['input'] | null;
      }> | null;
    }> | null;
    availableBundles: Array<{
      __typename?: 'AvailableBundle';
      description?: Types.Scalars['String']['input'] | null;
      expirationDate?: Types.Scalars['String']['input'] | null;
      id: Types.Scalars['String']['input'];
      name: Types.Scalars['String']['input'];
    }>;
    product?: {
      __typename?: 'OrderItemProduct';
      price: Types.Scalars['Float']['input'];
      customerPrice?: Types.Scalars['Float']['input'] | null;
      coreCharge?: Types.Scalars['Float']['input'] | null;
      fetCharge?: Types.Scalars['Float']['input'] | null;
      mapPrice?: Types.Scalars['Float']['input'] | null;
      listPrice?: Types.Scalars['Float']['input'] | null;
      backOrderable?: Types.Scalars['Boolean']['input'] | null;
      specialOrder?: Types.Scalars['Boolean']['input'] | null;
      partNumberDisplay: Types.Scalars['String']['input'];
      availabilityLines: Array<{
        __typename?: 'OrderItemProductAvailabilityLine';
        address?: Types.Scalars['String']['input'] | null;
        group?: Types.Scalars['String']['input'] | null;
        id?: number | null;
        name: Types.Scalars['String']['input'];
        quantity: number;
        type: Types.OrderItemProductAvailabilityLineType;
      }>;
      attributes: Array<{
        __typename?: 'OrderItemProductAttribute';
        name?: Types.Scalars['String']['input'] | null;
        value: Array<Types.Scalars['String']['input']>;
      }>;
      notes: Array<{ __typename?: 'OrderItemProductNotes'; notes: Array<Types.Scalars['String']['input']> }>;
      deliveryNotes: Array<{
        __typename?: 'OrderItemProductDeliveryNotes';
        label?: Types.Scalars['String']['input'] | null;
        text?: Types.Scalars['String']['input'] | null;
      }>;
      warnings: Array<
        | { __typename?: 'OrderItemProductAttachmentWarning'; attachment: Types.Scalars['String']['input'] }
        | { __typename?: 'OrderItemProductImageWarning'; path: Types.Scalars['String']['input'] }
        | { __typename?: 'OrderItemProductTextWarning'; text: Types.Scalars['String']['input'] }
      >;
    } | null;
    boschReward?: { __typename?: 'OrderBoschReward'; points: number } | null;
  } | null;
  brand?: {
    __typename?: 'Brand';
    logo?: Types.Scalars['String']['input'] | null;
    name: Types.Scalars['String']['input'];
  } | null;
  partType?: {
    __typename?: 'PartType';
    id: string;
    name: Types.Scalars['String']['input'];
    category?: { __typename?: 'PartCategory'; name: Types.Scalars['String']['input'] } | null;
  } | null;
  vehicle?: { __typename?: 'Vehicle'; id: string } | null;
  laborItems?: Array<{
    __typename?: 'CartLaborItem';
    id: string;
    hours: Types.Scalars['Float']['input'];
    defaultHours: Types.Scalars['Float']['input'];
    vin?: Types.Scalars['String']['input'] | null;
    orderItemId?: string | null;
    name: Types.Scalars['String']['input'];
    notes?: Types.Scalars['String']['input'] | null;
    skill: Types.Scalars['String']['input'];
    identifiers?: { __typename?: 'Mitchell1Identifiers' } | { __typename?: 'MotorIdentifiers'; id: string } | null;
    vehicle?: { __typename?: 'Vehicle'; id: string } | null;
  }> | null;
};

export const CartOrderItemFragmentDoc = `
    fragment CartOrderItem on CartOrderItem {
  id
  builtItem {
    sponsorType
    errors {
      message
    }
    bundled
    discount
    availableMotorLaborApplications @include(if: $withLabor) {
      duration
      id
      name
      notes
      parentId
      skillCode
      taxonomyName
      position {
        name
      }
      baseLaborTimeDescription
      descriptions
      attributes {
        name
        text
      }
    }
    availableBundles {
      description
      expirationDate
      id
      name
    }
    product {
      price
      customerPrice
      coreCharge
      fetCharge
      mapPrice
      listPrice
      backOrderable
      specialOrder
      partNumberDisplay
      availabilityLines {
        address
        group
        id
        name
        quantity
        type
      }
      attributes {
        name
        value
      }
      notes {
        notes
      }
      deliveryNotes {
        label
        text
      }
      warnings {
        ... on OrderItemProductAttachmentWarning {
          attachment
        }
        ... on OrderItemProductImageWarning {
          path
        }
        ... on OrderItemProductTextWarning {
          text
        }
      }
    }
    boschReward {
      points
    }
  }
  image
  brand {
    logo
    name
  }
  partName
  partNumber
  partNumberId
  lineCardId
  origin
  partType {
    id
    name
    category {
      name
    }
  }
  quantity
  tireSize
  vehicle {
    id
  }
  vin
  laborItems {
    ...CartLaborItem
  }
}
    ${CartLaborItemFragmentDoc}`;
