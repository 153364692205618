import { SearchTypes } from 'types/search';
import { RecentSearchAbstract } from './RecentSearchAbstract';
import type { AbstractParams } from './RecentSearchAbstract';
import type { RecentSearchInterface } from 'features/recentSearches';
import type { SearchEntryPointInterface } from 'features/searchForm';
import type { Vehicle } from 'models';
import type { PartTypeApplication } from 'shared/api';
import type { NonAppSearchFormValues } from 'types/search';

export class NonApp extends RecentSearchAbstract implements RecentSearchInterface, SearchEntryPointInterface {
  type: string = 'Non application';

  partTypes: Array<{ id: string; name: string; application: PartTypeApplication }>;

  attributes: Array<{ name: string; values: string[] }>;

  partTextId: string | null | undefined;

  partText: string | null | undefined;

  constructor(
    id: string,
    vehicle: Vehicle | null,
    params: AbstractParams & {
      partTypes?: Array<{ name: string; id: string; application: PartTypeApplication }> | null;
      attributes?: Array<{ name: string; values: string[] }> | null;
      partText?: { id?: string | null; text: string } | null;
    }
  ) {
    super(id, vehicle, params);

    this.partTypes = params.partTypes ?? [];
    this.attributes = params.attributes ?? [];
    this.partTextId = params.partText?.id ?? null;
    this.partText = params?.partText?.text ?? '';
  }

  isVehicleRequired(): boolean {
    return false;
  }

  populateInput(): string {
    return this.partText ?? '';
  }

  toString(): string {
    if (this.partTypes.length > 0) {
      return this.partTypes.map((partType) => partType.name).join(', ');
    }

    const attributeValues = this.attributes.map((attribute) => attribute.values.join(', ')).join(', ');

    return [this.partText, attributeValues].join(', ');
  }

  toSearchValues(): NonAppSearchFormValues {
    return {
      type: SearchTypes.PARTS,
      vehicleId: this.vehicle?.id ?? null,
      vin: this.vin,
      plate: this.plate,
      state: this.state,
      partText: this.partText ?? '',
      partTextId: this.partTextId ?? '',
      partTypes: this.partTypes.map((partType) => Number(partType.id)),
      attributes: this.attributes.reduce(
        (attributes, attribute) => ({ ...attributes, [attribute.name]: attribute.values }),
        {}
      ),
    };
  }
}
