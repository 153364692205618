import { Banner, Typography } from '@partstech/ui';
import type { OrderItemProductDeliveryNotes } from 'shared/api';

type Props = {
  notes: OrderItemProductDeliveryNotes[];
};

export const DeliveryNotes = ({ notes }: Props) => {
  if (notes.length === 0) {
    return null;
  }

  return (
    <Banner background="mono6" px={2} py={1} data-testid="eta">
      {notes.map((note) => (
        <Typography key={note.label} variant="caption" component="p">
          {note.label}: {note.text}
        </Typography>
      ))}
    </Banner>
  );
};
