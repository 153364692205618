import { Box, Card } from '@partstech/ui';
import { useMemo } from 'react';
import { Attribute } from 'components/Product/Attributes/Attribute';
import type { ProductAttribute } from 'types/product';

type Props = {
  attributes: ProductAttribute[];
};

export const Attributes = ({ attributes }: Props) => {
  const filteredAttributes = useMemo(() => attributes.filter((attribute) => !attribute.isHidden), [attributes]);

  if (filteredAttributes.length === 0) {
    return null;
  }

  return (
    <Box display="flex" flexWrap="wrap" gap={2} data-testid="attributes">
      {filteredAttributes.map((attribute) => (
        <Card key={attribute.name} py={1} px={2}>
          <Attribute attribute={attribute} />
        </Card>
      ))}
    </Box>
  );
};
