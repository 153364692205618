import { useCallback } from 'react';
import { useCreateShopManagementSystemMutation } from 'features/shop/api/createShopManagementSystem';
import { FullStoryEventNames, useFullStory } from 'integrations/fullstory';
import {
  useMutationCallback,
  type CreateShopManagementSystemRelationInput,
  type UseMutationCallbackArgs,
} from 'shared/api';
import { useGetCurrentUser } from 'store/queries/currentUser/useGetCurrentUser';
import type { CreateShopManagementSystemMutation } from 'features/shop/api/createShopManagementSystem/CreateShopManagementSystem.generated';

const NO_SMS = 'NO SMS';

const matchesCurrentSystem = (
  { managementSystemId, noSms }: CreateShopManagementSystemRelationInput,
  currentSystem: { id: string | null; name: string } | null
): boolean => {
  if (!currentSystem) {
    return false;
  }

  return (noSms && currentSystem.name === NO_SMS) || managementSystemId === currentSystem.id;
};

export const useCreateManagementSystem = ({
  onSuccess,
  ...args
}: UseMutationCallbackArgs<CreateShopManagementSystemMutation, {}> = {}) => {
  const { sendEvent } = useFullStory();
  const { managementSystem } = useGetCurrentUser();

  const handleSuccess = useCallback(
    (data: CreateShopManagementSystemMutation) => {
      sendEvent(FullStoryEventNames.SELECT_SMS);
      onSuccess?.(data);
    },
    [onSuccess, sendEvent]
  );

  const [createManagementSystem, status] = useMutationCallback(useCreateShopManagementSystemMutation, {
    onSuccess: handleSuccess,
    ...args,
  });

  const handleCreateManagementSystem = useCallback(
    async (input: CreateShopManagementSystemRelationInput) => {
      const isMatch = matchesCurrentSystem(input, managementSystem);

      if (isMatch) {
        onSuccess?.({ createShopManagementSystemRelation: { shop: { managementSystem } } });
        return;
      }

      await createManagementSystem({ input });
    },
    [createManagementSystem, managementSystem, onSuccess]
  );

  return [handleCreateManagementSystem, status] as const;
};
