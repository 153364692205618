import { css } from '@emotion/react';
import { Icon } from '@partstech/ui';
import { useMemo } from 'react';
import { useSearchPreferredBrands } from 'hooks/search';
import { useSearchResults } from 'pages/SearchResults/SearchResultsContext';
import { Options } from '../../DefaultFilter/Options';
import type { OptionsProps } from '../../DefaultFilter/Options/types';
import type { Theme } from '@partstech/ui';

const styles = {
  icon: (theme: Theme) => css`
    vertical-align: bottom;
    margin-left: ${theme.sizing(1)};
  `,
};

export const BrandOptions = ({ options, ...props }: OptionsProps) => {
  const { products } = useSearchResults();
  const { preferredBrandOptions } = useSearchPreferredBrands(products);

  const preferredBrandNames = useMemo(
    () => preferredBrandOptions.map((preferredBrand) => preferredBrand.name),
    [preferredBrandOptions]
  );

  const preferredOptions = useMemo(
    () => options.filter((option) => preferredBrandNames.includes(option.name)),
    [options, preferredBrandNames]
  );

  const regularOptions = useMemo(
    () => options.filter((option) => !preferredBrandNames.includes(option.name)),
    [options, preferredBrandNames]
  );

  return (
    <>
      <Options
        options={preferredOptions}
        nameAsValue
        adornmentAfter={<Icon name="loyalty" color="primaryLight10" size="large" css={styles.icon} />}
        {...props}
      />
      <Options options={regularOptions} nameAsValue {...props} />
    </>
  );
};
