import { entries } from '@partstech/ui/utils';
import { useMemo } from 'react';
import { useAppLocation } from 'app/AppRouter/useAppLocation';
import { CATALOG_GROUP } from 'constant';
import { createPartTypesFromQuery } from 'factories';
// eslint-disable-next-line no-restricted-imports
import { KeywordSearch } from 'features/searchForm/models/KeywordSearch';
import { LegacyJob, PartNumber, PartType, PartTypeList, TireConfiguration, UniversalPartType } from 'models';
import { useGetPartTypeGroup } from 'store/queries/partTypes/useGetPartTypeGroup';
import { usePunchoutInfo } from 'store/queries/punchoutInfo/usePunchoutInfo';
import { useGetVehicle } from 'store/queries/vehicle/useGetVehicle';
import { SearchSequenceType, SearchTypes } from 'types/search';
import { decodeSearchParams, formatPartTypesQuery, getSearchTypeBySearchParams } from 'utils';
import { useFullPartTypes } from '../partType/useFullPartTypes';
import type { SearchEntryPointInterface } from 'features/searchForm';
import type { Vehicle } from 'models';
import type { SearchParams } from 'types/search';

const getValuableParamsBySearchParams = ({
  vehicle,
  vin,
  plate,
  state,
  part_text,
  part_text_id,
  partnumber,
  tire_id,
  tire_sizes,
  part_types,
  type,
  debug,
  attributes,
  task_type,
  job_id,
  step,
  fit,
}: SearchParams) => {
  const values = {
    vehicle,
    vin,
    plate,
    state,
    part_text,
    part_text_id,
    partnumber,
    tire_id,
    tire_sizes,
    part_types,
    type,
    debug,
    attributes,
    task_type,
    job_id,
    step,
    fit,
  };

  return entries(values).reduce<SearchParams>((acc, [key, value]) => {
    if (!value) {
      return acc;
    }
    return {
      ...acc,
      [String(key)]: value,
    };
  }, {});
};

const getListTitle = (smsName: string | null, poNumber: string | null) => {
  if (smsName) {
    return poNumber ? `${smsName} RO #${poNumber}` : `${smsName} quote`;
  }

  return poNumber ? `RO #${poNumber}` : '';
};

const usePartTypeSearchEntryFromSearchParams = (
  partTextId: string = '',
  partTypeIds: string = '',
  partText?: string,
  attributes?: Record<string, string[]>
) => {
  const ids = partTextId.length ? [partTextId] : formatPartTypesQuery(partTypeIds);

  const { smsName, poNumber, isLoaded, punchoutEnabled } = usePunchoutInfo();

  const partTypeListTitle = getListTitle(smsName, poNumber);

  const type = punchoutEnabled ? SearchSequenceType.PunchoutPartTypes : SearchSequenceType.PartTypes;

  const { partTypes, isSuccess } = useFullPartTypes(
    { ids, alias: partText },
    { skip: LegacyJob.isLegacyJobId(partTextId) || (partTextId.length === 0 && partTypeIds.length === 0) }
  );

  const partType = partTypes[0] ?? null;

  const searchEntry = useMemo(() => {
    if (ids.length > 1) {
      return new PartTypeList(partTypes, partTypeListTitle, type);
    }

    if (partType instanceof UniversalPartType) {
      return partType.setSelectedAttributes(attributes ?? {});
    }

    return partType;
  }, [attributes, ids.length, partType, partTypeListTitle, partTypes, type]);

  return {
    searchEntry,
    partType,
    isSuccess: isSuccess && isLoaded,
  };
};

const useLegacyJobSearchEntryFromSearchParams = (partTextId: string = '', partText?: string) => {
  const { partTypeGroup, isSuccess, isFetching } = useGetPartTypeGroup({
    id: partTextId.replace('j_', ''),
    skip: !LegacyJob.isLegacyJobId(partTextId),
  });

  const searchEntry = useMemo(() => {
    if (LegacyJob.isLegacyJobId(partTextId) && partText && partTextId) {
      return new LegacyJob({
        id: partTextId,
        name: partText,
        aliases: [],
        partTypes: createPartTypesFromQuery(partTypeGroup?.partTypes ?? []),
      });
    }

    return null;
  }, [partTextId, partText, partTypeGroup?.partTypes]);

  return { searchEntry, isSuccess: isSuccess && !isFetching };
};

export type SearchParamsReturnType = {
  hasValidationInSearchParams: boolean;
  isCatalogSelected: boolean;
  isDealersSearch: boolean;
  isPartNumberSearch: boolean;
  isPartsSearch: boolean;
  isReady: boolean;
  isTiresSearch: boolean;
  partNumber: PartNumber | null;
  partType: PartType | UniversalPartType | null;
  searchEntry: SearchEntryPointInterface | null;
  searchParams: SearchParams;
  selectedSupplierId: string | null;
  step?: string;
  tireConfiguration: TireConfiguration | null;
  type: SearchTypes | null;
  valuableParams: SearchParams;
  vehicle: Vehicle | null;
  vehicleId: number | null;
};

export const useSearchParams = (): SearchParamsReturnType => {
  const { search } = useAppLocation();

  const searchParams = useMemo(() => decodeSearchParams(search), [search]);

  const {
    dealers,
    manufacturers,
    part_text,
    part_text_id,
    part_types,
    partnumber,
    selected_distributor,
    tire_sizes,
    vehicle: vehicleParam,
    vin,
    plate,
    state,
    attributes,
    step,
  } = searchParams;

  const searchType = useMemo(() => getSearchTypeBySearchParams(searchParams), [searchParams]);

  const {
    partType,
    searchEntry: partTypeSearchEntry,
    isSuccess: isPartTypeLoaded,
  } = usePartTypeSearchEntryFromSearchParams(part_text_id, part_types, part_text, attributes);

  const tireConfiguration = useMemo(
    () => (tire_sizes ? TireConfiguration.createFromString(tire_sizes) : null),
    [tire_sizes]
  );

  const partNumber = useMemo(
    () =>
      partnumber
        ? new PartNumber({ id: partnumber, partNumber: partnumber, brandName: manufacturers?.[0] ?? null, searchType })
        : null,
    [manufacturers, partnumber, searchType]
  );

  const { searchEntry: legacyJobSearchEntry, isSuccess: isSuccessLegacyJob } = useLegacyJobSearchEntryFromSearchParams(
    part_text_id,
    part_text
  );

  const keyword = useMemo(() => {
    if (part_text && !part_text_id) {
      return new KeywordSearch(part_text);
    }

    return null;
  }, [part_text, part_text_id]);

  const selectedSupplierId = selected_distributor || null;

  const valuableParams = useMemo(() => getValuableParamsBySearchParams(searchParams), [searchParams]);

  const vehicleId = vehicleParam ? Number(vehicleParam) : null;

  const platePayload = useMemo(() => (plate && state ? { plate, state } : undefined), [plate, state]);

  const { vehicle, isSuccess: isSuccessVehicle } = useGetVehicle({ id: vehicleParam, vin, plate: platePayload });

  const isDealersSearch = Boolean(dealers);
  const isPartNumberSearch = Boolean(partnumber);
  const hasValidationInSearchParams = 'validation' in searchParams;

  const searchEntry: SearchEntryPointInterface | null = useMemo(
    () => partNumber || partTypeSearchEntry || keyword || tireConfiguration || legacyJobSearchEntry || null,
    [keyword, legacyJobSearchEntry, partNumber, partTypeSearchEntry, tireConfiguration]
  );

  const isVehicleReady = Boolean(platePayload) || Boolean(vin) || Boolean(vehicleParam) ? isSuccessVehicle : true;

  const isSearchEntryReady = useMemo(() => {
    if (searchEntry instanceof PartType) {
      return isPartTypeLoaded;
    }

    if (searchEntry instanceof LegacyJob) {
      return isSuccessLegacyJob;
    }

    return true;
  }, [isPartTypeLoaded, isSuccessLegacyJob, searchEntry]);

  const isReady = isVehicleReady ? isSearchEntryReady : false;

  const type = searchEntry === null && !searchParams.type ? null : getSearchTypeBySearchParams(searchParams);

  return {
    isCatalogSelected: selectedSupplierId === String(CATALOG_GROUP),
    isPartsSearch: searchType === SearchTypes.PARTS,
    isTiresSearch: searchType === SearchTypes.TIRES,
    isPartNumberSearch,
    isDealersSearch,
    isReady,
    searchParams,
    selectedSupplierId,
    valuableParams,
    /** @deprecated use vehicle.id * */
    vehicleId,
    hasValidationInSearchParams,
    partNumber,
    partType,
    tireConfiguration,
    searchEntry,
    type,
    vehicle,
    step,
  };
};
