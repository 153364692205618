import { isEqual } from '@partstech/ui/utils';
import { useMemo } from 'react';
import { useAppSelector } from 'store';
import { selectLastSearch } from 'store/features/search/settings';
import { useSearchParams } from './useSearchParams';

export const useIsSameSearch = () => {
  const { valuableParams, searchParams } = useSearchParams();
  const lastSearch = useAppSelector(selectLastSearch);

  const { prefill } = searchParams;

  return useMemo(
    // Prefill is added for a case with dynamic attributes
    () => isEqual(lastSearch, valuableParams) || prefill === 'none',
    [lastSearch, prefill, valuableParams]
  );
};
