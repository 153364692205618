import { isNotNull } from '@partstech/ui/utils';
import { useMemo, useCallback } from 'react';
import { shallowEqual } from 'react-redux';
import { useAppSelector } from 'store';
import { selectRawSlots } from 'store/features/quote';
import { useProduct } from 'store/queries/product/useProduct';
import { selectIdFromQuery } from 'utils';
import type { Product } from 'models';
import type { GetProductQueryVariables } from 'store/queries/product/GetProduct.generated';

type ProductsResponse = {
  isLoading: boolean;
  isSuccess: boolean;
  isFailed: boolean;
  products: (Product | null)[];
};

const defaultParams: GetProductQueryVariables = {
  partNumberId: '',
  accountId: undefined,
  lineCardId: undefined,
  partTypeId: undefined,
  vehicleId: undefined,
  vin: undefined,
};

export const useComparedQuoteProducts = () => {
  const rawSlots = useAppSelector(selectRawSlots, shallowEqual);

  const slotsUrlParams = useMemo(() => rawSlots.map((slot) => slot.urlParams).filter(isNotNull), [rawSlots]);

  const productIds = useMemo(() => slotsUrlParams.map(selectIdFromQuery), [slotsUrlParams]);

  const params: GetProductQueryVariables[] = useMemo(
    () =>
      slotsUrlParams.map((query) => ({
        partNumberId: query.partnumberid ?? '',
        accountId: query.credentialId ? String(query.credentialId) : undefined,
        lineCardId: query.linecard ? String(query.linecard) : undefined,
        partTypeId: query.part_term ? String(query.part_term) : undefined,
        vehicleId: query.vehicle ? String(query.vehicle) : undefined,
        vin: query.vin,
      })),
    [slotsUrlParams]
  );

  const product1 = useProduct(params[0] || defaultParams, !params[0]);
  const product2 = useProduct(params[1] || defaultParams, !params[1]);
  const product3 = useProduct(params[2] || defaultParams, !params[2]);
  const productsResponse = useMemo(() => [product1, product2, product3], [product1, product2, product3]);

  const { isLoading, isSuccess, isFailed, products }: ProductsResponse = useMemo(
    () =>
      productsResponse.reduce<ProductsResponse>(
        (acc, result) => ({
          isLoading: acc.isLoading || result.isLoading,
          isSuccess: acc.isSuccess || result.isSuccess,
          isFailed: acc.isFailed || result.isFailed,
          products: [...acc.products, result.product],
        }),
        { isFailed: false, isLoading: false, isSuccess: false, products: [] }
      ),
    [productsResponse]
  );

  const failedProductIds = useMemo(
    () => productsResponse.map((response, index) => (response.isFailed ? productIds[index] : null)).filter(isNotNull),
    [productIds, productsResponse]
  );
  const fetchedProductIds = useMemo(
    () => productsResponse.map((response, index) => (response.isSuccess ? productIds[index] : null)).filter(isNotNull),
    [productIds, productsResponse]
  );
  const fetchingProductIds = useMemo(
    () => productsResponse.map((response, index) => (response.isLoading ? productIds[index] : null)),
    [productIds, productsResponse]
  );

  const isProductLoading = useCallback((id: string) => fetchingProductIds.includes(id), [fetchingProductIds]);

  return {
    isError: isFailed,
    isLoading,
    isSuccess,
    products,
    failedProductIds,
    fetchedProductIds,
    isProductLoading,
  };
};
