import { Typography, Link, TextCut, OldNavigationListItem } from '@partstech/ui';
import { entries } from '@partstech/ui/utils';
import { useMemo, type MouseEvent, type ReactNode } from 'react';

type DataAttributes = Record<`data-${string}`, string | number>;

type Props = {
  title: string;
  subtitle?: string;
  link?: string;
  onClick?: (e?: MouseEvent) => void;
  isActive?: boolean;
  adornmentBefore: ReactNode;
} & DataAttributes;

export const NavigationItem = ({ title, subtitle, link, onClick, isActive, adornmentBefore, ...props }: Props) => {
  const dataAttributes: DataAttributes = useMemo(
    () =>
      entries(props).reduce((acc, [key, value]) => {
        if (key.startsWith('data-')) {
          return { ...acc, [key]: value };
        }

        return acc;
      }, {}),
    [props]
  );

  return (
    <OldNavigationListItem
      as={link ? <Link to={link} {...dataAttributes} /> : undefined}
      adornmentBefore={adornmentBefore}
      onClick={onClick}
      active={isActive}
      size="dense"
      fullWidth
      colorScheme="darkBlue"
      {...dataAttributes}
    >
      <Typography component="div" variant="subtitle2">
        <TextCut>{title}</TextCut>
      </Typography>

      {subtitle && (
        <Typography component="div" variant="caption" color="subtleText">
          {subtitle}
        </Typography>
      )}
    </OldNavigationListItem>
  );
};
