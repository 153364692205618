import { useMemo } from 'react';
import { MaxPreferredBrandsCount } from 'constant';
import { PreferredBrandsMode } from 'shared/api';
import { usePreferredBrandsMode } from 'store/queries/currentUser/usePreferredBrandsMode';
import { getProductBrandOptions, isPreferredBrand } from 'utils';
import { usePreferredBrands } from './usePreferredBrands';
import type { Product } from 'models';
import type { BrandHookType, BrandOption } from 'types/preferredBrand';

type Props = {
  products: Product[];
  type: BrandHookType;
  isPartNumberSearch?: boolean;
};

const sortBrandOptions = (options: BrandOption[]) =>
  options.sort((a, b) => {
    if (a.priority !== undefined && b.priority !== undefined) {
      return a.priority < b.priority ? -1 : 1;
    }

    return a.name.toLowerCase() < b.name.toLocaleLowerCase() ? -1 : 1;
  });

export const useBrandOptions = ({ products, type, isPartNumberSearch }: Props) => {
  const { tiresPreferredBrandsMode, partsPreferredBrandsMode } = usePreferredBrandsMode();

  const mode = type === 'Part' ? partsPreferredBrandsMode : tiresPreferredBrandsMode;
  const isCustomMode = mode === PreferredBrandsMode.Custom;
  const maxPreferredBrandsCount = type === 'Part' ? MaxPreferredBrandsCount.Parts : MaxPreferredBrandsCount.Tires;

  const { preferredBrands } = usePreferredBrands({ mode, type });

  const productBrandOptions = useMemo(() => getProductBrandOptions(products), [products]);
  const preferredProductBrands = useMemo(
    () =>
      preferredBrands.filter((brand) =>
        products.some((product) =>
          isPreferredBrand([brand])(product.brand?.id ?? '', product.brand?.name ?? '', product.partTypeId ?? '')
        )
      ),
    [preferredBrands, products]
  );

  const limitedPreferredBrands = useMemo(
    () => (isCustomMode ? preferredProductBrands : preferredProductBrands.slice(0, maxPreferredBrandsCount)),
    [isCustomMode, maxPreferredBrandsCount, preferredProductBrands]
  );

  return useMemo(() => {
    const preferredBrandOptions: BrandOption[] = [];
    const regularBrandOptions: BrandOption[] = [];

    if (mode === PreferredBrandsMode.Disable || isPartNumberSearch) {
      return {
        preferredBrandOptions: [],
        regularBrandOptions: sortBrandOptions(productBrandOptions),
      };
    }

    productBrandOptions.forEach((option) => {
      const preferredBrand = limitedPreferredBrands.find(
        (brand) => brand.id === option.value || brand.matchingBrandsIds?.includes(String(option.value))
      );

      preferredBrand
        ? preferredBrandOptions.push({
            ...option,
            priority: isCustomMode ? preferredBrand.priority : undefined,
          })
        : regularBrandOptions.push(option);
    });

    return {
      preferredBrandOptions: sortBrandOptions(preferredBrandOptions),
      regularBrandOptions: sortBrandOptions(regularBrandOptions),
    };
  }, [mode, isPartNumberSearch, productBrandOptions, limitedPreferredBrands, isCustomMode]);
};
