import { Banner, Typography } from '@partstech/ui';

type Props = {
  notes: string[];
};

export const Notes = ({ notes }: Props) => {
  if (notes.length === 0) {
    return null;
  }

  return (
    <Banner background="mono6" px={2} py={1} data-testid="notes">
      {notes.map((note, index) => (
        <Typography key={index} variant="bodyText2">
          {note}
        </Typography>
      ))}
    </Banner>
  );
};
