import { useMemo } from 'react';
import { useSearchPreferredBrands } from 'hooks/search';
import { useSearchResults } from 'pages/SearchResults/SearchResultsContext';
import { DefaultFilter } from '../DefaultFilter';
import { BrandOptions } from './BrandOptions';
import type { FilterProps } from '../DefaultFilter/types';
import type { BrandOption } from 'types/preferredBrand';

const optionsOrder = (brandOptions: BrandOption[]) =>
  brandOptions.reduce<{ [key: string]: number }>((acc, brand, index) => ({ ...acc, [brand.name]: index }), {});

export const BrandFilter = (props: FilterProps) => {
  const { products } = useSearchResults();
  const { preferredBrandOptions, regularBrandOptions } = useSearchPreferredBrands(products);

  const options = useMemo(() => {
    const preferredOptionsOrder = optionsOrder(preferredBrandOptions);
    const regularOptionsOrder = optionsOrder(regularBrandOptions);

    const getPriority = (name: string) => {
      if (preferredOptionsOrder[name] !== undefined) {
        return preferredOptionsOrder[name];
      }

      if (regularOptionsOrder[name] !== undefined) {
        return regularOptionsOrder[name] + preferredBrandOptions.length;
      }

      return 0;
    };

    return props.options.sort((a, b) => getPriority(a.name) - getPriority(b.name));
  }, [props.options, preferredBrandOptions, regularBrandOptions]);

  return <DefaultFilter {...props} options={options} OptionsComponent={BrandOptions} />;
};
