import { combineReducers } from '@reduxjs/toolkit';
import { store } from 'store/entities/store';
import { supplier } from 'store/entities/supplier';
import { supplierAccounts } from 'store/entities/supplierAccounts';

export const entities = combineReducers({
  store,
  supplier,
  supplierAccounts,
});
