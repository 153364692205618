import { useEffect } from 'react';
import { useMutationStatus } from 'shared/api';
import type { FieldValues, Path, UseFormReturn } from '@partstech/ui/forms';
import type { MutationStatusArgs } from 'shared/api';

type Args<FormData extends FieldValues> = MutationStatusArgs & {
  form: UseFormReturn<FormData>;
};

/**  @deprecated -> use useFormMutationCallback */
export const useFormStatus = <FormData extends FieldValues>({ form, ...args }: Args<FormData>) => {
  const { setError } = form;
  const { validationErrors } = useMutationStatus(args);

  useEffect(() => {
    if (validationErrors.length) {
      validationErrors.forEach((validationError) => {
        const { path } = validationError;

        if (path) {
          const inputIndex = path.indexOf('input');
          const fieldName = inputIndex >= 0 ? path.slice(inputIndex + 1).join('.') : 'root';

          setError(fieldName as Path<FormData>, validationError);
        }
      });
    }
  }, [validationErrors, setError]);
};
