import { isString } from '@partstech/ui/utils';
import { formatPartTypesQuery } from './formatPartTypesQuery';
import { getSearchTypeBySearchParams } from './getSearchTypeBySearchParams';
import type { SearchFormValues, SearchParams } from 'types/search';

export const adaptSearchParamsToSearchValues = (searchParams: SearchParams): SearchFormValues => {
  const params: SearchFormValues = {
    type: getSearchTypeBySearchParams(searchParams),
    vehicleId: null,
    vin: null,
    plate: null,
    state: null,
    partText: null,
    partTextId: null,
    partNumber: null,
    manufacturers: null,
    tireId: null,
    tiresSize: null,
    partTypes: null,
  };

  if (isString(searchParams.part_text)) {
    params.partText = searchParams.part_text.replace(/\+/g, ' ');
    params.partTextId = searchParams.part_text_id || null;
  }

  if (searchParams.vehicle) {
    params.vehicleId = searchParams.vehicle;
  }

  if (searchParams.vin) {
    params.vin = searchParams.vin;
  }

  if (searchParams.state && searchParams.plate) {
    params.state = searchParams.state;
    params.plate = searchParams.plate;
  }

  if (searchParams.partnumber) {
    params.partNumber = searchParams.partnumber;
  }

  if (searchParams.manufacturers) {
    params.manufacturers = searchParams.manufacturers ?? null;
  }

  if (searchParams.part_types) {
    params.partTypes = formatPartTypesQuery(searchParams.part_types).map(Number);
  }

  if (searchParams.tire_id) {
    params.tireId = searchParams.tire_id;
  }

  if (searchParams.tire_sizes) {
    params.tiresSize = searchParams.tire_sizes;
  }

  return params;
};
