import { Icon } from '@partstech/ui';
import { useCallback } from 'react';
import { useUserpilotTrigger, SupportKey } from 'integrations/userpilot';
import { NavigationItem } from '../NavigationItem';

type Props = {
  onClick?: () => void;
};

export const UserpilotWidgetButton = ({ onClick }: Props) => {
  const userpilotTrigger = useUserpilotTrigger();

  const openWidget = useCallback(() => {
    userpilotTrigger(SupportKey.Default);

    onClick?.();
  }, [onClick, userpilotTrigger]);

  return (
    <NavigationItem
      title="Help"
      adornmentBefore={<Icon name="help" color="subtleText" />}
      onClick={openWidget}
      data-testid="helpMenuButton"
    />
  );
};
